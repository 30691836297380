import React, { useState, useEffect } from "react";
import "./ResidentalVerification.css";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";

const ResidentalVerification = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOwnHouse, setIsOwnHouse] = useState(false);
  const [isRentHouse, setIsRentHouse] = useState(false);

  const [incorrect, setIncorrect] = useState(false);
  const [success, setSuccess] = useState(false);

  const hideTenancy = () => {
    const selected = document.getElementById("tenancy1").value;
    setIsOwnHouse(selected === "Rented");
    setIsRentHouse(selected === "Owned");
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));
    const userInfo = JSON.parse(localStorage.getItem("user-info"));

    if (token && token.login) {
      setUserData(userInfo);
    } else {
      navigate("/");
    }
  }, [navigate]);

  const API_URL = "https://humhai.in/residential/";

  const uploadResidentialData = async (formData) => {
    try {
      const response = await fetch(API_URL, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }

      const data = await response.json();
      // console.log(data, "data");

      if (response.status === 201) {
        alert("Form Submitted Successfully");
        window.location.reload();
        setSuccess(true);
      } else {
        alert("Form Submission Failed");
        setIncorrect(true);
      }
    } catch (error) {
      console.error("There was an error submitting the data:", error);
      setIncorrect(true);
    } finally {
      setLoading(false);
    }
  };

  const userInfo = JSON.parse(localStorage.getItem("user-info"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const hhid = generateHHID();
    setSubmissionCount(submissionCount + 1);

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key] !== null ? formData[key] : "");
    }
    data.append("residencial_hh_id", hhid);
    data.append(
      "residencial_user_form_submit",
      userInfo ? userInfo.first_name + userInfo.last_name : ""
    );
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    data.append("residencial_submission_date", formattedDate);
    try {
      const response = await uploadResidentialData(data);
      console.log("Data submitted successfully:", response);
    } catch (error) {
      console.error("There was an error submitting the data:", error);
    }
  };

  const handleFileChange = async (event, setStateFunction) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const fileType = file.type;

        if (
          fileType === "application/pdf" ||
          fileType === "image/jpeg" ||
          fileType === "image/png" ||
          fileType === "image/gif" ||
          fileType === "image/webp" ||
          fileType === "image/jpg"
        ) {
          if (fileType === "application/pdf") {
            setStateFunction(file);
          } else {
            const options = {
              maxSizeMB: 0.2,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };

            const compressedFile = await imageCompression(file, options);
            setStateFunction(compressedFile);
          }
        } else {
          console.error(
            "Unsupported file format. Please upload a PDF, JPG, JPEG, or PNG file."
          );
        }
      } catch (error) {
        console.error("Error processing file:", error);
      }
    }
  };

  const handleChange = async (e) => {
    const { name, value, type, checked, files } = e.target;

    if (type === "file" && files.length > 0) {
      await handleFileChange(e, (processedFile) => {
        setFormData({
          ...formData,
          [name]: processedFile,
        });
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const [formData, setFormData] = useState({
    residencial_name: "",
    residencial_fatherName: "",
    residencial_motherName: "",
    residencial_address: "",
    residencial_number: "",
    residencial_dob: "",
    residencial_gender: "",
    residencial_family_member: "",
    residencial_location: "",

    residencial_house_photo1: null,
    remark_photo1: "",
    residencial_house_photo2: null,
    remark_photo2: "",
    residencial_house_photo3: null,
    remark_photo3: "",
    residencial_house_photo4: null,
    remark_photo4: "",
    residencial_house_photo5: null,
    remark_photo5: "",
    residencial_house_photo6: null,
    remark_photo6: "",
    residencial_house_photo7: null,
    remark_photo7: "",
    residencial_house_photo8: null,
    remark_photo8: "",
    residencial_house_photo9: null,
    remark_photo9: "",
    residencial_house_photo10: null,
    remark_photo10: "",
    residencial_house_photo11: null,
    remark_photo11: "",
    residencial_house_photo12: null,
    remark_photo12: "",
    residencial_house_photo13: null,
    remark_photo13: "",
    residencial_house_photo14: null,
    remark_photo14: "",
    residencial_house_photo15: null,
    remark_photo15: "",

    residencial_reference_name: "",
    residencial_reference1_mobilenumber: "",
    residencial_reference1_relation: "",
    residencial_reference1_remark: "",
    residencial_reference2_name: "",
    residencial_reference2_mobilenumber: "",
    residencial_reference2_relation: "",
    residencial_reference2_remark: "",

    residencial_reference3_name: "",
    residencial_reference3_mobilenumber: "",
    residencial_reference3_relation: "",
    residencial_reference3_remark: "",
    residencial_reference4_name: "",
    residencial_reference4_mobilenumber: "",
    residencial_reference4_relation: "",
    residencial_reference4_remark: "",

    residencial_neighbour_name: "",
    residencial_neighbour1_mobilenumber: "",
    residencial_neighbour1_address: "",
    residencial_neighbour1_remark: "",
    residencial_neighbour2_name: "",
    residencial_neighbour2_mobilenumber: "",
    residencial_neighbour2_address: "",
    residencial_neighbour2_remark: "",

    residencial_is_own_house: false,
    residencial_owner_name: "",
    residencial_owner_contact: "",
    residing_date: "",
    residing_remark: "",
    own_house_living: "",

    residencial_bike: "",
    residencial_car: "",
    residencial_ac: "",
    residencial_other: "",
  });

  const [submissionCount, setSubmissionCount] = useState(0);

  const generateHHID = (idType = "R") => {
    const now = new Date();
    const hhidPrefix = "HH";
    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hour = now.getHours().toString().padStart(2, "0");
    const minute = now.getMinutes().toString().padStart(2, "0");
    const second = now.getSeconds().toString().padStart(2, "0");

    const newSubmissionCount = submissionCount + 1;
    setSubmissionCount(newSubmissionCount);

    const countPart = newSubmissionCount.toString().padStart(4, "0");

    return `${hhidPrefix}${year}${month}${day}${hour}${minute}${second}${idType}${countPart}`;
  };

  return (
    <div className="residental_containers">
      <div className="residental_container">
        <h1
          className="h1 text-center mb-5"
          style={{ marginTop: "20px", fontWeight: "bold" }}
        >
          Family<span style={{ color: "rgb(255, 119, 0)" }}> Details</span>
          <span> Residencial Verification</span>
        </h1>
        <form onSubmit={handleSubmit}>
          {/*  Personal Details  */}
          <div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="residencial_name"
                  className="form-control"
                  placeholder="Enter Your Full Name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="fatherName">Father's Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_fatherName"
                  placeholder="Enter Father's Name"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="motherName">Mother's Name (Optional)</label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_motherName"
                  placeholder="Enter Mother's Name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="familyNumber">How Many Family Members</label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_family_member"
                  placeholder="Enter family members"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_address">Address</label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_address"
                  placeholder="Enter Address"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_number">Contact Number</label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_number"
                  onChange={handleChange}
                  placeholder="Enter Contact Number"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_gender">Gender</label>
                <select
                  className="form-control"
                  name="residencial_gender"
                  onChange={handleChange}
                >
                  <option value="">Choose</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_dob">Date Of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  onChange={handleChange}
                  placeholder="Enter DOB"
                  name="residencial_dob"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_location">Location</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={handleChange}
                  placeholder="Enter Location"
                  name="residencial_location"
                />
              </div>
            </div>
          </div>

          {/* House Photos */}
          <div>
            <div>
              <h3
                className="h3 text-center mb-1"
                style={{
                  marginTop: "20px",
                  fontWeight: "bold",
                  fontSize: "34px",
                }}
              >
                House Photos Details
              </h3>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo1">House Photo 1</label>
                <input
                  type="file"
                  className="form-control"
                  name="residencial_house_photo1"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo1">House Photo 1 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 1 Remark"
                  name="remark_photo1"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo2">House Photo 2</label>
                <input
                  type="file"
                  className="form-control"
                  name="residencial_house_photo2"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo2">House Photo 2 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 2 Remark"
                  name="remark_photo2"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo3">House Photo 3</label>
                <input
                  type="file"
                  className="form-control"
                  name="residencial_house_photo3"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo3">House Photo 3 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 3 Remark"
                  name="remark_photo3"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo4">House Photo 4</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleChange}
                  name="residencial_house_photo4"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo4">House Photo 4 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 4 Remark"
                  name="remark_photo4"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo5">House Photo 5</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleChange}
                  name="residencial_house_photo5"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo5">House Photo 5 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 5 Remark"
                  name="remark_photo5"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo6">House Photo 6</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleChange}
                  name="residencial_house_photo6"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo6">House Photo 6 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 6 Remark"
                  name="remark_photo6"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo7">House Photo 7</label>
                <input
                  type="file"
                  className="form-control"
                  name="residencial_house_photo7"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo7">House Photo 7 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 7 Remark"
                  name="remark_photo7"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo8">House Photo 8</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleChange}
                  name="residencial_house_photo8"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo8">House Photo 8 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 8 Remark"
                  name="remark_photo8"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo9">House Photo 9</label>
                <input
                  type="file"
                  className="form-control"
                  name="residencial_house_photo9"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo9">House Photo 9 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  name="remark_photo9"
                  placeholder="Enter House Photo 9 Remark"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo10">
                  House Photo 10
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="residencial_house_photo10"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo10">House Photo 10 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 10 Remark"
                  name="remark_photo10"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo11">
                  House Photo 11
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="residencial_house_photo11"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo11">House Photo 11 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 11 Remark"
                  name="remark_photo11"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo12">
                  House Photo 12
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="residencial_house_photo12"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo12">House Photo 12 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 12 Remark"
                  name="remark_photo12"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo13">
                  House Photo 13
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="residencial_house_photo13"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo13">House Photo 13 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 13 Remark"
                  name="remark_photo13"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo14">
                  House Photo 14
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="residencial_house_photo14"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo14">House Photo 14 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 14 Remark"
                  name="remark_photo14"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_house_photo15">
                  House Photo 15
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="residencial_house_photo15"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="remark_photo15">House Photo 15 Remark</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter House Photo 15 Remark"
                  name="remark_photo15"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* Reference 1  */}
          <div>
            <h3
              className="h3 text-center mb-1"
              style={{
                marginTop: "20px",
                fontWeight: "bold",
                fontSize: "34px",
              }}
            >
              Reference Details
            </h3>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference_name">
                  Reference Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference_name"
                  placeholder="Enter Reference Name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference1_relation">
                  Reference Relation
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference1_relation"
                  placeholder="Enter Reference Relation"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference1_mobilenumber">
                  Reference 1 Mobile Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_reference1_mobilenumber"
                  placeholder="Enter Reference 1 Mobile Number"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference1_remark">
                  Reference 1 Remark
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference1_remark"
                  placeholder="Enter Reference 1 Remark"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference2_name">
                  Reference 2 Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference2_name"
                  placeholder="Enter Reference 2 Name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference2_relation">
                  Reference Relation
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference2_relation"
                  placeholder="Enter Reference 2 Relation"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference2_mobilenumber">
                  Reference 2 Mobile Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_reference2_mobilenumber"
                  placeholder="Enter Reference 2 Mobile Number"
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference2_remark">
                  Reference 2 Remark
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference2_remark"
                  placeholder="Enter Reference 2 Remark"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference3_name">
                  Reference 3 Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference3_name"
                  placeholder="Enter Reference 3 Name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference3_relation">
                  Reference 3 Relation
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference3_relation"
                  placeholder="Enter Reference 3 Relation"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference3_mobilenumber">
                  Reference 3 Mobile Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_reference3_mobilenumber"
                  placeholder="Enter Reference 3 Mobile Number"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference3_remark">
                  Reference 3 Remark
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference3_remark"
                  placeholder="Enter Reference 3 Remark"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference4_name">
                  Reference 4 Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference4_name"
                  placeholder="Enter Reference 4 Name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference4_relation">
                  Reference 4 Relation
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference4_relation"
                  placeholder="Enter Reference 4 Relation"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference4_mobilenumber">
                  Reference 4 Mobile Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_reference4_mobilenumber"
                  placeholder="Enter Reference 4 Mobile Number"
                  onChange={handleChange}
                />
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="residencial_reference4_remark">
                  Reference 4 Remark
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_reference4_remark"
                  placeholder="Enter Reference 4 Remark"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* neighbour 1  */}
          <div>
            <h3
              className="h3 text-center mb-1"
              style={{
                marginTop: "20px",
                fontWeight: "bold",
                fontSize: "34px",
              }}
            >
              Neighbour Details
            </h3>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_neighbour_name">
                  Neighbour Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_neighbour_name"
                  placeholder="Enter Neighbour Name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_neighbour1_mobilenumber">
                  Neighbour 1 Mobile Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_neighbour1_mobilenumber"
                  placeholder="Enter Neighbour 1 Mobile Number"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_neighbour1_address">
                  Neighbour 1 Since
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_neighbour1_address"
                  placeholder="Enter Neighbour 1 Since"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_neighbour1_remark">
                  Neighbour 1 Remark
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_neighbour1_remark"
                  placeholder="Enter Neighbour 1 Remark"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_neighbour2_name">
                  Neighbour 2 Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_neighbour2_name"
                  placeholder="Enter Neighbour 2 Name"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_neighbour2_mobilenumber">
                  Neighbour 2 Mobile Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_neighbour2_mobilenumber"
                  placeholder="Enter Neighbour 2 Mobile Number"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_neighbour2_address">
                  Neighbour 2 Since
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_neighbour2_address"
                  placeholder="Enter Neighbour 2 Since"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_neighbour2_remark">
                  Neighbour 2 Remark
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_neighbour2_remark"
                  placeholder="Enter Neighbour 2 Remark"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* House Ownership */}
          <div>
            <h3
              className="h3 text-center mb-1"
              style={{
                marginTop: "20px",
                fontWeight: "bold",
                fontSize: "34px",
              }}
            >
              House Ownership
            </h3>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="tenancy1">House Ownership</label>
                <select
                  id="tenancy1"
                  className="form-control"
                  onChange={hideTenancy}
                >
                  <option value="">Choose</option>
                  <option value="Owned">Owned</option>
                  <option value="Rented">Rented</option>
                </select>
              </div>
            </div>
            {isOwnHouse && (
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="residencial_owner_name">
                    House Owner Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="residencial_owner_name"
                    placeholder="Enter House Owner Name"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="residencial_owner_contact">
                    House Owner Contact
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="residencial_owner_contact"
                    placeholder="Enter House Owner Contact"
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="residencial_dob">Residing Date</label>
                  <input
                    type="date"
                    className="form-control"
                    onChange={handleChange}
                    name="residing_date"
                    placeholder="Enter Contact Number"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="residing_remark">Remark</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    name="residing_remark"
                    placeholder="Enter Contact Number"
                  />
                </div>
              </div>
            )}
            {isRentHouse && (
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="own_house_living">
                    How long have you been living in this house?
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    name="own_house_living"
                    placeholder="Enter Total Years"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="residing_remark">Remark</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    name="residing_remark"
                    placeholder="Enter Remark"
                  />
                </div>
              </div>
            )}
          </div>

          {/* Vehicle Details */}
          <div>
            <h3
              className="h3 text-center mb-1"
              style={{
                marginTop: "20px",
                fontWeight: "bold",
                fontSize: "34px",
              }}
            >
              Vehicle Details
            </h3>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_bike">Bike</label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_bike"
                  placeholder="Enter Bike Details"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_car">Car</label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_car"
                  placeholder="Enter Car Details"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="residencial_ac">Air Conditioner</label>
                <input
                  type="number"
                  className="form-control"
                  name="residencial_ac"
                  placeholder="Enter AC Details"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="residencial_other">Other</label>
                <input
                  type="text"
                  className="form-control"
                  name="residencial_other"
                  placeholder="Enter Other Details"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* button */}
          <div className="ms-4 mb-4 ">
            <div className="col-md-12">
              <button
                type="submit"
                className="btn btn-primary w-100"
                style={{ margin: "10px 0px", height: "50px" }}
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResidentalVerification;
