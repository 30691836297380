import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Components/Login";
import ResidentalVerification from "./Components/ResidentalVerification";
// import RegisterForm from "./Components/RegisterForm";
// import ForgotPassword from "./Components/ForgotPassword";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path='/register' element={<RegisterForm/>}/>
          <Route path="/forgot-password" element={<ForgotPassword/>}/> */}
          <Route path="/residental" element={<ResidentalVerification />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
