import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const LoginForm = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [incorrect, setIncorrect] = useState(false);
  const [incorrect1, setIncorrect1] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const csrftoken = getCookie("csrftoken");

    try {
      const response = await fetch("https://humhai.in/hrms/LoginAPI/", {
        method: "POST",
        body: JSON.stringify({ username: username, password: password }),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          "X-CSRFToken": csrftoken,
        },
      });

      const result = await response.json();
      if (result.status === true) {
        localStorage.setItem("user-info", JSON.stringify(result.user_data));
        localStorage.setItem(
          "token",
          JSON.stringify({ login: true, token: result.token })
        );

        setSuccess(true);
        setTimeout(() => {
          navigate("/residental");
          setSuccess(false);
        }, 3000);
      } else if (result.detail === "User not Found!") {
        setIncorrect1(true);
        setIncorrect(false);
      } else if (result.detail === "Incorrect Password!") {
        setIncorrect(true);
        setIncorrect1(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };                                      

  
  return (
    <div className="login-containers">
      <div className="login-container">
        <form onSubmit={handleSubmit} className="login-form">
          <h2>Login</h2>
          {incorrect1 && <p className="error-message">User not found!</p>}
          {incorrect && <p className="error-message">Incorrect password!</p>}
          {success && <p className="success-message">Login successful!</p>}
          <div>
            <label>Mobile Number:</label>
            <input
              type="number"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Loading..." : "Login"}
          </button>
          {/* <div className="links">
            <p onClick={() => navigate("/register")}>Register</p>
            <p onClick={() => navigate("/forgot-password")}>Forgot Password?</p>
          </div> */}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
